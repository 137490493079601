import { useCallback } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";

import { dialogPropsState } from "@/atoms/dialogState";

import Dialog from "./Dialog";

export function DialogRoot() {
  const dialogProps = useRecoilValue(dialogPropsState);
  const resetDialogProps = useResetRecoilState(dialogPropsState);
  const onDialogClose = useCallback(
    () => resetDialogProps(),
    [resetDialogProps]
  );

  return (
    <Dialog
      isOpen={dialogProps.children != ""}
      onClose={onDialogClose}
      {...dialogProps}
    />
  );
}
